<template>
    <div>
        <vx-card class="filter-card">
            <vs-row>
                <vs-col vs-w="9.4">
                <h4>Lead Search</h4>
                </vs-col>
                <vs-col vs-w="2.6">
                <!-- <vs-button color="dark" icon="arrow_back"  type="filled" @click="getBack()">Back</vs-button> -->
                </vs-col>
            </vs-row>
            <vs-row class="mt-6">
                <vs-col vs-w="2.5">
                    <div style="display: flex">
                        <vs-input label="Mobile Number" v-model="mobile_no" @input="restrictInput" />
                        <vs-button color="#6200ee" icon="search" style="margin: 19px 0 0 5px" :disabled="!mobile_no.length"
                            @click="searchWithInput()">
                        </vs-button>
                    </div>
                </vs-col>
                <vs-col vs-w="2.5" class="move-left">
                    <div style="display: flex">
                        <vs-input label="Can-ID" v-model="can_id" />
                        <vs-button color="#6200ee" icon="search" style="margin: 19px 0 0 5px" :disabled="!can_id.length"
                            @click="searchWithInput()"></vs-button>
                    </div>
                </vs-col>
                <vs-col vs-w="3" vs-type="flex" class="move-left">
                    <div style="display: flex">
                        <vs-input label="Email" v-model="mail_id" />
                        <vs-button color="#6200ee" icon="search" style="margin: 19px 0 0 5px" :disabled="!mail_id.length"
                            @click="searchWithInput()">
                        </vs-button>
                        
                    </div>
                </vs-col>
                <vs-col vs-w="1" vs-type="flex">
                    <vs-button color="#6200ee" style="margin: 19px 0 0 10px" @click="clearNumber" icon="close">
                        </vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" class="mt-4"><vs-button
                            color="#0044BA"
                            style="margin-left: 2%"
                            type="filled"
                            @click="generateReport()"
                            v-if="login_spoc_id == 132 || login_spoc_id == 937 || login_spoc_id == 39"
                            >Generate Report</vs-button></vs-col>
            </vs-row>
            <div class="mt-10">
                <lead-search-tabulator :all_leads="all_leads" v-if="all_leads.length != 0"></lead-search-tabulator>
                <div v-if="all_leads.length == 0" style="color: grey; margin: 5px">
                    <center>NO DATA AVAILABLE</center>
                </div>
            </div>
        </vx-card>

        <vx-card class="filter-card mt-6" v-if="showColumnNames">
            <vs-row class="mt-6">
                <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
                    <p class="filter-heading">Select Columns</p>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5"
                class="enrollment-tree-drop none_padding" id="spoc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                    Columns
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown" style="z-index: 60000; width: 25%;">
                    <treeselect v-model="initSelectedColumns" :multiple="true" :options="treeDatacolumns" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row>

            <vs-row class="mt-6 mb-2">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" class="none_padding" vs-w="12">
                <div class="aplproval_search_criteria mt-4">
                  <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
                  <vs-row vs-w="12" class="mt-2 mb-2">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="10.7">
                      <div style="display: inline-block; width: 100%">
                        <span v-for="chip in selectedColData" :key="chip">
                          <vs-chip closable style="color: black; border-radius: 5px;"
                            @click="removechip(chip, 'COL')" color="#BCEBFA">
                            <b>{{ getFulName(chip) }}</b>
                          </vs-chip>
                        </span>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
            <vs-row class="mt-8 mb-2">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-offset="8" vs-align="center" vs-w="2" class="mt-4"><vs-button
                            color="danger"
                            style="margin-left: 2%"
                            type="filled"
                            @click="showColumnNames = false"
                            >Cancel</vs-button></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2" class="mt-4"><vs-button
                            color="#0044BA"
                            style="margin-left: 2%"
                            type="filled"
                            @click="submitReport()"
                            v-if="login_spoc_id == 132 || login_spoc_id == 937 || login_spoc_id == 39"
                >Submit Report</vs-button></vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";
import LeadSearchTabulator from "./LeadSearchTabulator.vue"
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    components: {
        LeadSearchTabulator,
        Treeselect,
    },
    data() {
        return {
            can_id: "",
            mail_id: "",
            mobile_no: "",
            currentTablePage: 1,
            all_leads: [],
            login_spoc_id: "",
            showColumnNames: false,
            selectedColData: [],
            initSelectedColumns: [],
            treeDatacolumns: [
            {
                id: "All",
                label: "All",
                children: [],
            },
            ],
        };
    },
    watch: {
        initSelectedColumns: function (val) {
            console.log('selected cols', val);
            if((!val.includes('serial_no') || !val.includes('can_id') || !val.includes('sheet_can_id') || !val.includes('sheet_email') || !val.includes('sheet_phone')) && !val.includes("All")){
                this.initSelectedColumns = ['serial_no', 'can_id', 'sheet_can_id', 'sheet_email', 'sheet_phone']
            }
            this.mergeAllSearch();
        },
    },
    mounted() {
        this.login_spoc_id = localStorage.getItem("spoc_id");
    },
    methods: {
        getFulName(val){
            return val.replace(/_/g, " ");
        },
        showTreeBox() {
            console.log("clicked");
        },
        generateReport() {
            // let url = `${constants.MILES_CM_BACK}startPDReportGeneration`;
            // let url = `${constants.MILES_CM_BACK}startPDReportGeneration?name=LeadGenerationReport`;    
            // axios
            //     .get(url, {
            //         headers: {
            //         Authorization: `Bearer ${localStorage.userAccessToken}`,
            //         },
            //     })
            //     .then((response) => {
            //         console.log("response", response.data);
            //         if(response.data.status === 'success'){
            //             this.$vs.notify({
            //             title: `Success`,
            //             text: response.data.message,
            //             color: "success",
            //             });
            //         }else if(response.data.status === 'error'){
            //             this.$vs.notify({
            //             title: `Error`,
            //             text: response.data.message,
            //             color: "danger",
            //             });
            //         }
            //     }).catch((error) => {
            //         this.handleError(error);
            //     });
            this.showColumnNames = true;
            this.getColumnNames();
        },
        getColumnNames(){
            let url = `${constants.MILES_CM_BACK}leadGenerationFilters`;    
            axios
                .get(url, {
                    headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("Col names", response.data);
                    this.treeDatacolumns[0].children = [];
                    for (let j = 0; j < response.data.length; j++) {
                        const element = response.data[j];
                        const obj = {
                            id: element,
                            label: element.replace(/_/g, " "),
                        };
                        this.treeDatacolumns[0].children.push(obj);
                    }
                    this.initSelectedColumns = ['All'];
                }).catch((error) => {
                    this.handleError(error);
                });
        },
        mergeAllSearch() {
            this.selectedColData = [];
            if(this.initSelectedColumns.length != 0){
                this.initSelectedColumns.forEach((sort) => {
                    if (sort === "All") {
                        this.treeDatacolumns[0].children.forEach((child) => {
                                this.selectedColData.push(child.id);
                        });
                    } else {
                        this.selectedColData.push(sort);
                    }
                });
            }else{
                this.initSelectedColumns = ['serial_no', 'can_id', 'sheet_can_id', 'sheet_email', 'sheet_phone']
            }
        },
        removechip(chip, name) {
            if (name == "COL" && chip != 'can_id' && chip != 'sheet_can_id' && chip != 'sheet_email' && chip != 'sheet_phone' && chip != 'serial_no') {
            if (this.initSelectedColumns.indexOf(chip) === -1) {
                this.initSelectedColumns = [];
                this.treeDatacolumns[0].children.forEach((child) => {
                if (child.id !== chip) {
                    this.initSelectedColumns.push(child.id);
                    this.selectedColData.push(child.id);
                }
                });
            } else {
                this.initSelectedColumns.splice(this.initSelectedColumns.indexOf(chip), 1);
                this.selectedColData.splice(this.selectedColData.indexOf(chip), 1);
            }
            } 
        },
        submitReport(){
            let url = `${constants.MILES_CM_BACK}startPDReportGeneration?name=LeadGenerationReport&selected_columns=${this.selectedColData.toString()}`;    
            axios
                .get(url, {
                    headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("response", response.data);
                    if(response.data.status === 'success'){
                        this.$vs.notify({
                        title: `Success`,
                        text: response.data.message,
                        color: "success",
                        });
                        this.showColumnNames = false
                    }else if(response.data.status === 'error'){
                        this.$vs.notify({
                        title: `Error`,
                        text: response.data.message,
                        color: "danger",
                        });
                        this.showColumnNames = false
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
        },
        restrictInput() {
            this.mobile_no = this.mobile_no.replace(/[^0-9]/g, '');
        },
        clearNumber() {
            this.can_id = "";
            this.mail_id = "";
            this.mobile_no = "";
            this.all_leads = [];
        },
        validateNumber(number) {
            if ((number.length > 10 || number.length < 10)) {
                this.$vs.notify({
                    title: "Please enter 10 digits mobile number",
                    text: "Enter a valid number",
                    color: "danger",
                });
                this.$vs.loading.close();
                return false;
            }else {
                return true;
            }
        },
        searchWithInput() {
            if (this.mobile_no != '' && !this.validateNumber(this.mobile_no)) {
                this.$vs.loading.close();
                return;
            }
            this.$vs.loading();
            let obj = {
                identity: this.can_id,
                email: this.mail_id,
                mobile: this.mobile_no,
            }
            this.all_leads = [];
            console.log("searchWithInput", obj);
            let url = `${constants.SERVER_API}getIndividualLeadsSearch?page=${this.currentTablePage}`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    this.all_leads = response.data.data;
                    for (let i = 0; i < this.all_leads.length; i++) {
                        const element = this.all_leads[i];
                        if (element.mhp_date != null) {
                            element.mhp_date = this.convertTimestampToCustomFormat(element.mhp_date);
                        }
                        if (element.last_call != null) {
                            element.last_call = this.convertTimestampToCustomFormat(element.last_call);
                        }
                        if (element.next_call != null) {
                            element.next_call = this.convertTimestampToCustomFormat(element.next_call);
                        }
                        if (element.sr_last_call != null) {
                            element.sr_last_call = this.convertTimestampToCustomFormat(element.sr_last_call);
                        }
                        if (element.sr_next_call != null) {
                            element.sr_next_call = this.convertTimestampToCustomFormat(element.sr_next_call);
                        }
                        if (element.macc_last_call != null) {
                            element.macc_last_call = this.convertTimestampToCustomFormat(element.macc_last_call);
                        }
                        if (element.macc_next_call != null) {
                            element.macc_next_call = this.convertTimestampToCustomFormat(element.macc_next_call);
                        }
                        console.log("element.enrollment_date", element.enrollment_date);
                        if (element.enrollment_date != '' && element.enrollment_date != null) {
                            element.enrollment_date = this.convertTimestampToCustomFormat(element.enrollment_date);
                        }
                        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
                        Object.assign(element, { erollment_form: "https://cdn-icons-png.flaticon.com/512/61/61183.png" });
                        Object.assign(element, { iiml_erollment_form: "https://cdn-icons-png.flaticon.com/512/61/61183.png" });
                    }
                    this.currentTablePage = response.data.current_page;
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        convertTimestampToCustomFormat(date) {
            let new_date = moment.unix(date).format("DD-MMM-YYYY");
            return new_date;
        },
        getBack(){
            this.$router.push("/adminDashboard");
        },
    },
};
</script>
<style scoped>
.move-left {
    margin-left: -10px !important;
}

.desc-border-box p {
    display: flex;
    justify-content: center;
    padding-inline: 20px;
    align-items: center;
    background-color: #6200ee;
    border-radius: 10px 10px;
    width: 80px;
    height: 37.8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-top: 18px;
    margin-left: 12px;

    color: #ffffff;
}

.no-padd {
    padding: 0% !important;
}
.back-icon{
  color: rgba(255, 255, 255, 0.466) !important;
  display:flex;
  justify-content:flex-start;
  margin: -15px 0 0 -12px;
  font-size: 26px !important;
  font-weight: 900;
  position:absolute
}
.back-icon:hover{
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: rgba(255, 255, 255, 0.116) !important;
  border-radius: 7px;
}
.enrollment-tree-drop>.vs-con-dropdown {
    width: 100%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
  }
  .enrollment-tree-drop#spoc>.vs-con-dropdown {
    background: rgba(188, 235, 250, 0.69);
    border: 1px solid #1fabd8;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
  }
  .ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    padding-inline-start: 10px;
  }
  
  .ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
  }
  .a-iconns>.material-icons {
    margin-left: auto;
  }
  
  .a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  i {
    font-size: 18px;
  }
  
  .a-iconns {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
  }
  .aplproval_search_criteria {
    width: 100%;
    background: #ffffff;
    min-height: 139px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
  .Criteria_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .Criteria_chip {
    border-radius: 5px;
  }
  .Criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
  }
  
  /* .criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
  }
  
  .criteria_chip .vs-chip--text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #000000;
  } */
  .filter-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  /* .con-vs-dropdown--menu {
    width: 20%;
    } */
</style>